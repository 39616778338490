const route = [
  {
    path: "/",
    name: "gestion_des_saisons__informations_generales",
    meta: {
      "can" : false,
      "title": "Informations générales"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_saisons" */ "@/modules/GestionDesSaisons/InformationsGenerales.vue"
      ),
  },

  {
    path: "documents-a-joindre",
    name: "gestion_des_saisons__documents_a_joindre",
    meta: {
      "can" : true,
      "title": "Documents à joindre"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_saisons" */ "@/modules/GestionDesSaisons/DocumentsAJoindre.vue"
      ),
  },
  {
    path: "gestion-salaries",
    name: "gestion_des_saisons__gestion_des_salaries",
    meta: {
      "can" : true,
      "title": "Gestion des salariés"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_saisons" */ "@/modules/GestionDesSaisons/GestionDesSalaries.vue"
      ),
  },

  {
    path: "cotisation-licence",
    name: "gestion_des_saisons__cotisations_des_licences",
    meta: {
      "can" : true,
      "title": "Cotisation des licences"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_saisons" */ "@/modules/GestionDesSaisons/CotisationLicence.vue"
      ),
  },



  {
    path: "representants-ag-national",
    name: "gestion_des_saisons__representants_ag_national",
    meta: {
      "can" : true,
      "title": "Représentants AG national"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_saisons" */ "@/modules/GestionDesSaisons/RepresentantsAgNational.vue"
      ),
  },

];

export default route;
