/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */

import { apolloClient } from "@/vue-apollo";
import { error_notification } from "@/plugins/notifications";

// import {isEmpty} from "@/plugins/utils"

const mutations = {
  SET_STRUCTURE_MEMBERS(state, payload) {
    // console.log(payload);
    state.structures[payload.index].UTI_Utilisateurs = payload.data;
  },

  UPDATE_USER_FONCTION_ON_STRUCTURE(state, payload) {
    // let users = state.structures[payload.index].UTI_Utilisateurs;
    // let userToUpdate = users.find(
    //   (user) => user.ID_Utilisateur == payload.data.ID_Utilisateur
    // );
    // userToUpdate.ID_Fonction = payload.data.ID_Fonction;
    // userToUpdate.FonctionUtilisateur = payload.data.FonctionUtilisateur;
    // userToUpdate.DescriptionFonction = payload.data.DescriptionFonction;
  },

  REMOVE_USER_FONCTION_ON_STRUCTURE(state, payload) {
    // let users = state.structures[payload.index].UTI_Utilisateurs;
    // let userToUpdate = users.find(
    //   (user) => user.ID_Utilisateur == payload.data.ID_Utilisateur
    // );
    // userToUpdate.ID_Fonction = null;
    // userToUpdate.FonctionUtilisateur = null;
    // userToUpdate.DescriptionFonction = "" ;
  },

  UPDATE_STRUCTURE_NOM_CORRESPONDANT(state, payload) {
    state.structures[payload.index].STR_Structure.ID_NomCorrespondant =
      payload.data.id;
  },

  UPDATE_STRUCTURE_FEDERAL_ORDRE(state, payload) {
    state.structures.find(
      (obj) => obj.id === payload.data.ID_StructureUtilisateur
    ).OrdreStructureFederal = payload.data.OrdreStructureFederal;
  },

  UPDATE_STRUCTURE_USER_FIRST_CONNECTION(state, payload) {
    state.structures[payload.index].EST_PremiereConnexion = false;
  },

  UPDATE_USER_EST_SALARIER(state, payload) {
    // let users =  state.structures[payload.index].UTI_Utilisateurs
    console.log(state.structures[payload.index]);
    // users.find(user => user.ID_Utilisateur == payload.ID_Utilisateur ).EST_Salarier = payload.EST_Salarier
  },

  SET_SRUCTURE_LICENCE_UTILISATEUR(state, payload) {
    let user = state.structures[payload.index].UTI_Utilisateurs.find(
      (user) => user.ID_Utilisateur == payload.data.ID_Utilisateur
    );
    let userIndex = state.structures[payload.index].UTI_Utilisateurs.indexOf(
      user
    );
    let licences =
      state.structures[payload.index].UTI_Utilisateurs[userIndex]
        .UTI_Utilisateur.UTI_Licences;

    if (!licences) {
      licences = [];
    }
    if (licences.find((lic) => lic.id == payload.data.id)) {
      let indexLicence = licences.indexOf(
        licences.find((lic) => lic.id == payload.data.id)
      );
      licences[indexLicence] = payload.data;
    }

    state.structures[payload.index].UTI_Utilisateurs[
      userIndex
    ].UTI_Utilisateur.UTI_Licences.push(payload.data);
  },
};

const actions = {
  // @params payload Int
  async getStructureMembre({ dispatch }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Utilisateurs/get_utilisateur_structure.gql"),
      variables: {
        // ID_Structure: payload.ID_Structure,
        // ID_Saison: payload.ID_Saison,
        whereClause: payload.where,
      },
      fetchPolicy: 'network-only'
    });
    return response.data.STR_StructureUtilisateur;
  },

  async getStructureUtilisateur({ }, payload) {
    let query = {
      query: require("@/graphql/Structures/get_structure_utilisateur.gql"),
      variables: {
        ID_Utilisateur: payload.ID_Utilisateur,
        ID_Structure: payload.ID_Structure,
      },
    };
    return await apolloClient
      .query(query)
      .then((response) => {
        return response.data.STR_StructureUtilisateur[0];
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //@params payload {ID_Structure Int, str_type String}
  async getAllStructureMembre({ }, payload) {
    return await apolloClient
      .query({
        query: require("@/graphql/Utilisateurs/get_utilisateur_" +
          payload.StructureType +
          ".gql"),
        variables: { ID_Structure: payload.ID_Structure },
      })
      .then((response) => {
        return response.data.STR_StructureUtilisateur;
      })
      .catch((e) => console.log(e));
  },

  async updateUserFonctionOnStructure({ commit, getters }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/update_utilisateur_structure_fonction.gql"),
      variables: {
        ID_Structure: payload.Structure_ID ?? payload.ID_Structure,
        ID_Utilisateur: payload.Utilisateur_ID ?? payload.ID_Utilisateur,
        ID_Fonction: payload.Fonction_ID ?? payload.ID_Fonction,
        FonctionDescription: payload.FonctionDescription ?? payload.DescriptionFonction,
      },
    });
    let data = response.data.update_STR_StructureUtilisateur.returning;

    return data[0];
  },

  async createUserFonctionOnStructure({ commit, getters }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/create_utilisateur_structure_fonction.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Utilisateur: payload.ID_Utilisateur,
        ID_Fonction: payload.ID_Fonction,
        FonctionDescription: payload.DescriptionFonction,
        EST_RoleFederal: payload.EST_RoleFederal,
        EST_Actif: payload.EST_Actif,
      },
    });
    let data = response.data.insert_STR_StructureUtilisateur.returning;

    return data[0];
  },

  async updateStructureNomCorrespondant({ commit, getters }, payload) {
    let response = apolloClient.mutate({
      mutation: require("@/graphql/Structures/update_structure_utilisateur_correspondant.gql"),
      variables: {
        Structure_id: getters.currentStructureId,
        Utilisateur_id: payload.id,
      },
    });
    commit("UPDATE_STRUCTURE_NOM_CORRESPONDANT", {
      index: getters.currentIndex,
      data: payload,
    });
    return response;
  },

  async updateOrdreStructureFederal({ commit }, payload) {
    let response = apolloClient.mutate({
      mutation: require("@/graphql/Structures/update_structure_utilisateur_order.gql"),
      variables: {
        ID_StructureUtilisateur: payload.ID_StructureUtilisateur,
        OrdreStructureFederal: payload.OrdreStructureFederal,
      },
    });

    commit("UPDATE_STRUCTURE_FEDERAL_ORDRE", { data: payload });
    return response;
  },

  async updateFirstConnection({ commit, getters }, user_id) {
    let response = apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/update_utilisateur_structure_first_connection.gql"),
      variables: {
        ID_Structure: getters.currentStructureId,
        ID_Utilisateur: user_id,
        EST_PremiereConnexion: false,
      },
    });

    commit("UPDATE_STRUCTURE_USER_FIRST_CONNECTION", {
      index: getters.currentIndex,
      ID_Utilisateur: user_id,
    });
    return response;
  },

  async updateStructreSalarieUser({ getters, commit }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/update_utilisateur_EST_Salarier.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Utilisateur: payload.user.ID_Utilisateur,
        EST_Salarier: payload.EST_Salarier,
      },
      update: (store, { data: { update_STR_StructureUtilisateur } }) => {
        // let query = {
        //  query: require("@/graphql/Utilisateurs/get_users_in_structure.gql"),
        //   variables: {
        //     ID_Structure: payload.ID_Structure,
        //   }
        // }
        // let data = store.readQuery(query)
        // let user = update_STR_StructureUtilisateur.returning[0]
        // console.log(data)
        // console.log(user)
      },
    });

    // console.log(getters.structureIndex(payload.ID_Structure));

    // console.log(response);
  },

  async insertLicenceUtilisateur({ commit, getters }, payload) {
    console.log("ato :>> ");
    if (!payload.ID_EtapeLicence) payload.ID_EtapeLicence = 1;

    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Licences/insert_licence_utilisateur.gql"),
      variables: {
        licence: payload,
      },
    });

    let data = response.data.insert_UTI_Licence.returning[0];
    if (data) {
      commit("SET_SRUCTURE_LICENCE_UTILISATEUR", {
        index: getters.structureIndex(data.ID_Structure),
        data: data,
      });
    }
    return data;
  },

  async updateLicenceUtilisateur({ commit, getters }, payload) {
    if (!payload.items.length) return false;

    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Licences/insert_licence_utilisateur.gql"),
      variables: {
        licence: payload.items,
        rmID_UTI_LicenceAssurance: payload.rmID_UTI_LicenceAssurance,
      },

      update: (store, { data: { insert_UTI_Licence } }) => {
        // We update now each query we want to re render the good results
        let query = {
          query: require("@/graphql/Licences/get_licence_utilisateur.gql"),
          variables: { ID_Utilisateur: payload.items[0].ID_Utilisateur },
        };
        const data = store.readQuery(query);
        data.UTI_Licence[0].UTI_LicenceAssurance =
          insert_UTI_Licence.returning[0].UTI_LicenceAssurance;

        return;
      },
    });

    let data = response.data.insert_UTI_Licence.returning;
    //console.log(data)
    if (data.length) {
      data.forEach((Ndata) => { });
    }
    return data;
  },

  async updateLicenceUtilisateurStructureList({ commit, getters }, payload) {
    if (!payload.items.length) return false;

    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Licences/insert_licence_utilisateur.gql"),
      variables: {
        licence: payload.items,
        rmID_UTI_LicenceAssurance: payload.rmID_UTI_LicenceAssurance,
        idUtilisateur: payload.items[0].ID_Utilisateur,
        estLicencie: payload.estLicencie,
      },

      update: (store, { data: { insert_UTI_Licence } }) => {
        // We update now each query we want to re render the good results
        let query = {};
        query = {
          query: require("@/graphql/Licences/get_licence_utilisateur.gql"),
          variables: {
            ID_Utilisateur: payload.items[0].ID_Utilisateur,
          },
        };
      },
    });
    return response.data.insert_UTI_Licence.returning;
  },

  async updateLastLicenceUtilisateur({ }, payload) {
    // console.log(payload)
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/update_last_licence_utilisateur.gql"),

      variables: {
        users: payload.data,
      },
    });
    return response.data.insert_STR_StructureUtilisateur.returning;
  },

  async updateNewLicenceNumero({ }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Licences/update_licence_numero.gql"),

      variables: {
        idUser: payload.idUser,
        licenceNumero: payload.licenceNumero,
      },
    });
    return response;
  },

  async addAssuranceOptionUtilisateur({ }, payload) { },

  async getSalarieTasks() {
    let response = await apolloClient.query({
      query: require("@/graphql/Utilisateurs/get_salarie_tasks.gql"),
    });
    return response.data.LIST_MissionSalarie;
  },

  async updateSalarieTask(ctx, { ID_Utilisateur, ID_Mission }) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Utilisateurs/update_salarie_task.gql"),

      variables: {
        ID_Utilisateur,
        ID_Mission,
      },
    });
    return response;
  },
};

export { actions, mutations };
