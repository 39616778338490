import fetch from "node-fetch";

let axios = require("axios");

const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const objectIsEqual = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

const isEmpty = (obj) => {
  for (var key in obj) {
    if (key) return false;
  }
  return true;
};

const translateDate = (date, toRFC2822) => {
  // TODO make it wok with any custom format
  let new_date;
  let debug = false;

  if (toRFC2822) {
    if (date.substr(5, 1) !== "/") {
      // if (debug)
      //   console.log(
      //     "datePicker > translateDate, input is not dd/mm/yyyy",
      //     date
      //   );
      return date;
    }
    new_date =
      date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2);
  } else {
    // to custom format
    if (date.substr(7, 1) !== "-") {
      // if (debug)
      //   console.log(
      //     date,
      //     date.substr(7, 1),
      //     "datePicker > translateDate, input is not RFC2822"
      //   );
      return date;
    }
    new_date =
      date.substr(8, 2) + "/" + date.substr(5, 2) + "/" + date.substr(0, 4);
  }
  return new_date;
};

const listDeCivilite = () => {
  return [
    { text: "Homme", value: 1 },
    { text: "Femme", value: 0 },
  ];
};

const slugify = (string) => {
  return string.toLowerCase().replace(" ", "_");
};

const capitalizeFirstLetter = (string) => {
  let str = string.split(" ");
  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }
  if (str.length > 1) {
    return str.join(" ");
  }
  return str[0];
};

// function which trim the string and capitalize the first letter
const trimAndCapitalizeFirstLetter = (string) => {
  if (!string) return "";
  let str = string.trim();
  str = str[0].toUpperCase() + str.substr(1);
  return str;
};

// function which trim the string and uppercase all the letters and let only one space between words if there is more than one
const trimAndUppercaseAllLetters = (string) => {
  if (!string) return "";
  let str = string.trim();
  str = str.toUpperCase();
  str = str.replace(/\s+/g, " ");
  return str;
};

const randomNb = (min, max) => {
  let randomNum = Math.random() * (max - min) + min;
  return Math.round(randomNum);
};

const randomStr = (len, arr) => {
  var ans = "";
  for (var i = len; i > 0; i--) {
    ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
};

// function which control the APP_ENV
const canDoActionInThisEnvMode = (methodName) => {
  if (process.env.VUE_APP_ENV !== "production") {
    console.log(
      "An action will not be done from the " +
        methodName +
        " because the environment is dev or test or staging"
    );
    return false;
  }
  return true;
};

const generateFakeUserUuidForTest = () => {
  const { v4: uuidv4 } = require("uuid");
  return uuidv4();
};

const isLocalLinkModeDevEnv = () => {
  return process.env.VUE_APP_ENV !== "production";
};

// function which control the APP_ENV
const canDoActionInProdMode = async (methodName) => {
  return process.env.VUE_APP_ENV === "production";
};

// SEND EMAIL FUNCTION
// -- SendPaymentLink => paiement horsclub
const send_email = async (params, publicEMail) => {
  // if (!canDoActionInThisEnvMode("send_email")) return { ok: true };
  try {
    const fetch = require("node-fetch");
    const headers = {
      "Content-Type": "application/json",
    };
    // Si pas de params public email, on envoi le mail
    if (!publicEMail) {
      headers.Authorization =
        "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME);
    }
    const response = await fetch(
      process.env.VUE_APP_FFME_BACK_URL + "/api/sendemail",
      {
        method: "post",
        body: JSON.stringify(params),
        headers: headers,
      }
    );
    const json = await response.json();
    return { ok: true };
  } catch (error) {
    console.log("erro r:", error);
    return { ok: false };
  }

  // if(response.status == 200) {
  //   return {ok: true}
  // }
  // return {ok: false}
};

const resend_email_user = async (username) => {
  // if (!canDoActionInThisEnvMode("resend_email_user")) return { ok: true };
  try {
    const fetch = require("node-fetch");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
    };

    const response = await fetch(
      process.env.VUE_APP_FFME_BACK_URL + "/api/users/" + username + "/reset-password",
      {
        method: "put",
        headers: headers,
        body: JSON.stringify({})
      }
    );
    const json = await response.json();
    return { ok: true };
  } catch (error) {
    console.log("erro r:", error);
    return { ok: false };
  }
};

const admin_email_update = async (params) => {
  // if (!canDoActionInThisEnvMode("admin_email_update")) return { ok: true };
  try {
    const fetch = require("node-fetch");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
    };
    const response = await fetch(
      process.env.VUE_APP_FFME_BACK_URL + "/api/users/" + params.username + "/update-email",
      {
        method: "put",
        body: JSON.stringify({newEmail: params.newEmail}),
        headers: headers,
      }
    );
    const json = await response.json();
    console.log("json adminemailupdate:", json);

    if (json.type == "UserNotFoundException") {
      return { ok: false };
    }
    return { ok: true };
  } catch (error) {
    console.log("erro r:", error);
    return { ok: false };
  }
};

const update_user_email = async (newEmail) => {
  // if (!canDoActionInThisEnvMode("update_user_email")) return { ok: true };
  try {
    const fetch = require("node-fetch");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
    };
    const response = await fetch(
      process.env.VUE_APP_FFME_BACK_URL + "/api/users/update-email",
      {
        method: "put",
        body: JSON.stringify({newEmail}),
        headers: headers,
      }
    );
    const json = await response.json();
    console.log("json adminemailupdate:", json);
    if (json.type == "UserNotFoundException") {
      return { ok: false };
    }
    return { ok: true };
  } catch (error) {
    console.log("erro r:", error);
    return { ok: false };
  }
};

const update_infos_user = async (params) => {
  // if (!canDoActionInThisEnvMode("update_infos_user")) return { ok: true };
  try {
    const fetch = require("node-fetch");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
    };
    const response = await fetch(
      process.env.VUE_APP_FFME_BACK_URL + "/api/users/" + params.username,
      {
        method: "put",
        body: JSON.stringify(params),
        headers: headers,
      }
    );
    const json = await response.json();
    return { ok: true };
  } catch (error) {
    console.log("erro r:", error);
    return { ok: false };
  }
};

// Fonction pour récupérer le bon tarif en fonction du nombre d'adhérents
const getTarifByNbUsers = (tarifs, nombreAdherents) => {
  const sortedTarifs = tarifs.sort((a, b) => {
    const regExp = /\d+$/;
    const aNombre = parseInt(a.NomTarif.match(regExp)[0]);
    const bNombre = parseInt(b.NomTarif.match(regExp)[0]);
    return bNombre - aNombre;
  });

  let selectedTarif = null;
  for (const tarif of sortedTarifs) {
    const regExp = /\d+$/;
    const tarifNombre = parseInt(tarif.NomTarif.match(regExp) ? tarif.NomTarif.match(regExp)[0] : 0);
    if (nombreAdherents >= tarifNombre) {
      selectedTarif = tarif;
      break;
    }
  }
  return selectedTarif;
}

export {
  generateFakeUserUuidForTest,
  canDoActionInThisEnvMode,
  send_email,
  resend_email_user,
  admin_email_update,
  update_user_email,
  string_to_slug,
  sleep,
  objectIsEqual,
  isEmpty,
  slugify,
  translateDate,
  listDeCivilite,
  capitalizeFirstLetter,
  randomNb,
  randomStr,
  update_infos_user,
  trimAndCapitalizeFirstLetter,
  trimAndUppercaseAllLetters,
  getTarifByNbUsers,
};

// import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";
